import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import Loading from "../../../components/Loader/Spinners";
import ShopifyNoAuthService from "../../../utils/Services/ShopifyNoAuth";
import ShopifyEmbeddedService from "../../../utils/Services/ShopifyEmbedded";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import MissingQueryParams from "./components/MissingQueryParams";
import MissingShopify from "../../../pages/MissingShopify";

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = localStorage.getItem("token");

  const shopifyNoAuthService = new ShopifyNoAuthService();
  const shopifyEmbeddedService = new ShopifyEmbeddedService();

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const shop = queryParams.get("shop");

  //   if (!shop) {
  //     // Redirect to an error page or display an error message
  //     return;
  //   }

  //   const app = createApp({
  //     apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  //     shopOrigin: shop,
  //     forceRedirect: true,
  //   });

  //   // Check authentication status
  //   const isAuthenticated = localStorage.getItem("shopifyToken");

  //   if (!isAuthenticated) {
  //     const redirect = Redirect.create(app);
  //     redirect.dispatch(
  //       Redirect.Action.REMOTE,
  //       `${process.env.REACT_APP_BACKEND_URL}/auth?shop=${shop}`,
  //     );
  //   }
  // }, [searchParams]);
  useEffect(() => {
    const verifyAndAuthenticateUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        // Verify the existing token
        try {
          const res = await shopifyEmbeddedService.post_data("/verifyToken");
          // Assuming your verifyToken endpoint responds with a boolean validity field
          if (res.isValid) {
            setIsAuthenticated(true);
            setLoading(false);
          } else {
            localStorage.removeItem("token"); // Token is invalid, remove it
            authenticateUser(); // Proceed to authenticate the user as usual
          }
        } catch (err) {
          console.error("Error verifying token:", err);
          authenticateUser(); // On error, proceed to authenticate the user
        }
      } else {
        authenticateUser(); // No token, proceed to authenticate the user
      }
    };

    const authenticateUser = () => {
      const shop = searchParams.get("shop");
      if (shop) {
        const query = Array.from(searchParams)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        shopifyNoAuthService
          .get_data(`/verifyUser?${query}`)
          .then((res) => {
            localStorage.setItem("user", JSON.stringify(res));
            localStorage.setItem("token", res?.token?.accessToken);
            setIsAuthenticated(true);
          })
          .catch((err) => {
            handleNavigate(shop);
            setIsAuthenticated(false);
            // redirectToNewPage("/shopify/callback");
          })
          .finally(() => setLoading(false));
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };

    verifyAndAuthenticateUser();
  }, [searchParams]); // Include other dependencies as necessary
  const handleNavigate = (shop: string) => {
    // Extract the store name from the shopName state
    let formattedShopName = shop.trim();

    // Check if the input includes 'http://' or 'https://'
    if (formattedShopName.includes("http://")) {
      formattedShopName = formattedShopName.replace("http://", "");
    } else if (formattedShopName.includes("https://")) {
      formattedShopName = formattedShopName.replace("https://", "");
    }
    formattedShopName = formattedShopName.replace(/\/$/, "");
    // shopifyService
    //   .get_data(`/auth?shop=${}`)
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err))
    //   .finally(() => console.log);

    window.open(
      `${process.env.REACT_APP_ERP_BACKEND}/shopify/auth?shop=${formattedShopName}`,
      "_blank",
    );
  };

  return (
    <>
      {loading ? (
        <Loading isLoading={loading} />
      ) : isAuthenticated ? (
        <Outlet />
      ) : (
        <MissingShopify />
        // <div className="flex flex-col-reverse items-center justify-center gap-16 px-4 py-24 md:gap-28 md:px-44 md:py-20 lg:flex-row lg:px-24 lg:py-24">
        //   <div className="relative w-full pb-12 lg:pb-0 xl:w-1/2 xl:pt-24">
        //     <div className="relative">
        //       <div className="absolute">
        //         <div>
        //           <h1 className="my-2 text-2xl font-bold text-gray-800">
        //             Looks like the query parameters provided are no longer up to
        //             date.
        //           </h1>
        //           <p className="my-2 text-gray-800">
        //             We are using them to generate the access token and pass
        //             authentification requirements. Please refresh the page in
        //             Shopify embedded app or provide an up to date query params.
        //           </p>
        //         </div>
        //       </div>
        //       <div>
        //         <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
        //       </div>
        //     </div>
        //   </div>
        //   <div>
        //     <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
        //   </div>
        // </div>
      )}
    </>
  );
};

export default PrivateRoutes;
