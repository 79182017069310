import EmbeddedDefaultService from "../Default/embedded.auth";
const COLLECTION = "shopify/embedded";

class ShopifyEmbeddedService extends EmbeddedDefaultService {
  constructor() {
    super(COLLECTION);
  }
  async get_data(endPoint: string): Promise<any> {
    const res = await this.fetchJson<any>(endPoint, {
      method: "GET",
    });
    return res;
  }
  async post_data(endPoint: string, body: any = []) {
    const res = await this.fetchJson<any>(endPoint, {
      method: "POST",
      body: JSON.stringify(body),
    });
    return res;
  }
  async put_data(endPoint: string, body: any = []) {
    const res = await this.fetchJson<any>(endPoint, {
      method: "PUT",
      body: JSON.stringify(body),
    });
    return res;
  }
}

export default ShopifyEmbeddedService;
