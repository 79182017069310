const headers = {
  "Content-Type": "application/json",
};

class DefaultService {
  public collection: string;
  protected baseAPI = process.env.REACT_APP_ERP_BACKEND;

  constructor(collection: string) {
    this.collection = collection;
  }
  redirectWithSearchParams(redirectTo: string) {
    const searchParams = window.location.search; // Capture current search params
    window.location.href = `${redirectTo}${searchParams}`; // Redirect with search params
  }
  async fetchJson<T>(endPoint: string, options?: RequestInit): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(`${this.baseAPI}/${this.collection}${endPoint}`, {
        ...options,
        headers: {
          ...headers,
          ...(options?.headers || {}),
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
}

export default DefaultService;
