import React, { lazy, Suspense } from "react";
import SuspenseLoader from "./components/Loader/Suspense";
import Layout from "./layout";
import PrivateRoutes from "./middleware/Authentification/PrivateRoutes";
import MissingQueryParams from "./middleware/Authentification/PrivateRoutes/components/MissingQueryParams";

const Loader = (Component: React.FC) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Status404 = Loader(lazy(() => import("./pages/Helpful/Error/404")));
const ComingSoon = Loader(lazy(() => import("./pages/Helpful/ComingSoon")));

const CreateShopifyStore = Loader(
  lazy(() => import("./pages/CreateShopifyStoreNew/index")),
);
const Dropshipping = Loader(lazy(() => import("./pages/Dropshipping")));
const MissingShopify = Loader(lazy(() => import("./pages/MissingShopify")));
const MissingAliexpress = Loader(
  lazy(() => import("./pages/MissingAliexpress")),
);
const comingSoonPages = [
  "/dashboard",
  "/myProducts",
  "/trending",
  "/content",
  "/settings",
  "/integration",
  "/shopifyOnBoard",
  "/printifyOnBoard",
  "/storeBuilder",
  "/userOnBoard",
];

const routes = () => [
  {
    element: <PrivateRoutes />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <CreateShopifyStore />,
          },
          { path: "/integration/shopify", element: <MissingShopify /> },
          { path: "/integration/aliexpress", element: <MissingAliexpress /> },
          {
            path: "/dropshipping",
            element: <Dropshipping />,
          },
          // {
          //   path: "/mockups",
          //   element: <GenerateMockup />,
          // },
          // {
          //   path: "/integration",
          //   element: <Integration />,
          // },
          {
            path: "*",
            element: comingSoonPages.includes(window.location.pathname) ? (
              <ComingSoon />
            ) : (
              <Status404 />
            ),
          },
        ],
      },
    ],
  },

  // {
  //   path: "/shopify/callback",
  //   element: <LoginShopifyPage />,
  // },
  // {
  //   path: "/login",
  //   element: <LoginShopifyPage />,
  // },
];

export default routes;
