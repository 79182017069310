import React, { FC, InputHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
interface CustomLabeledProps extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
}
interface CustomLineDefaulInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
}
export const CustomLabeledInput: FC<CustomLabeledProps> = ({
  title,
  id,
  className,
  ...props
}) => {
  return (
    <div className="flex w-full flex-col gap-1 font-outfit">
      {title && (
        <label
          className="w-full text-left text-sm font-medium text-[#1c1a22]"
          htmlFor={id}
        >
          {title}
        </label>
      )}
      <input
        className={twMerge(
          `h-12 w-full rounded-lg border  border-[#c9c4d0] bg-transparent px-3.5 py-3 
      text-black focus:border-[#c9c4d0] focus:text-black`,
          className,
        )}
        id={id}
        type="text"
        {...props}
      />
    </div>
  );
};
export const CustomLineInput: FC<CustomLineDefaulInputProps> = ({
  title,
  id,
  ...props
}) => {
  return (
    <div className="flex w-full flex-col">
      {title && (
        <label
          htmlFor={id}
          className="mb-1 flex-shrink-0 flex-grow-0 text-left text-sm font-medium text-text-icons-grey-30"
        >
          {title}
        </label>
      )}
      <input
        className="  block w-full min-w-[200px] rounded-lg border border-gray-300 bg-white p-2.5 text-sm
       text-gray-900  focus:border-primary-blue-500 focus:ring-primary-blue-500"
        id={id}
        type="text"
        {...props}
      />
    </div>
  );
};
