export const ProfileService = {
  getToken: () => {
    return localStorage.getItem("token");
  },
  getUser: () => {
    return localStorage.getItem("user");
  },
  isAuthenticated: () => {
    
    return localStorage.getItem("token") != null;
  },
};
