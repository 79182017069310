import "./index.css";
import { PulseLoader } from "react-spinners";
import { LoadingInterface } from "./index.d";

const Loading = ({ text = "Loading", isLoading = false }: LoadingInterface) => {
  return isLoading ? (
    <div className="fixed inset-0 z-[999] flex flex-col items-center justify-center bg-gray-900 bg-opacity-75">
      <PulseLoader size={30} color={"#AB2FB7"} />
      <h2 className="text-2xl text-white">{text}</h2>
    </div>
  ) : null;
};

export default Loading;
