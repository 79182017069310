import { ProfileService } from "./profile.service";

const headers = {
  "Content-Type": "application/json",
};

class EmbeddedDefaultService {
  public collection: string;
  protected baseAPI = process.env.REACT_APP_ERP_BACKEND;

  constructor(collection: string) {
    this.collection = collection;
  }
  intercept(url: string, config: any): any {
    if (ProfileService.isAuthenticated()) {
      // get user session profile from profile service
      let _token = ProfileService.getToken();
      let _config = { ...config }; // shallow copy of the config
      _config.headers = _config.headers || {}; // make sure headers is initialized
      _config.headers["authorization"] = "Bearer " + _token;

      return [url, _config];
    } else {
      localStorage.clear();
    }
  }
  // Function to redirect while preserving search parameters
  redirectWithSearchParams(redirectTo: string) {
    const searchParams = window.location.search; // Capture current search params
    window.location.href = `${redirectTo}${searchParams}`; // Redirect with search params
  }
  async fetchJson<T>(endPoint: string, options?: RequestInit): Promise<T> {
    return new Promise((resolve, reject) => {
      const [url, config] = this.intercept(
        `${this.baseAPI}/${this.collection}${endPoint}`,
        {
          ...options,
          headers: {
            ...headers,
            ...(options?.headers || {}),
          },
        },
      );
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((err) => {
              throw { response: res, message: err.message || "Unknown error" };
            });
          }
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  async fetchCustomContentType(
    endPoint: string,
    options?: RequestInit,
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      const [url, config] = this.intercept(
        `${this.baseAPI}/${this.collection}${endPoint}`,
        {
          ...options,
          headers: {
            ...headers,
            ...(options?.headers || {}),
          },
        },
      );
      fetch(url, config)
        .then((res) => {
          checkStatusAction(res.status);
          return res;
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
}
function checkStatusAction(status: number) {
  if (status === 405) {
    // Get current query parameters
    const queryParams = window.location.search;

    // Redirect to /integrations with the same query params
    window.location.href = `${window.location.origin}/integration/aliexpress${queryParams}`;
  }
  return;
}

export default EmbeddedDefaultService;
