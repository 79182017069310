import { configureStore, EntityState } from "@reduxjs/toolkit";


import editorFontsReducer from "../Reducers/FontReducer";
import { FontState } from "../Reducers/FontReducer/index.d";

import { TutorialsState } from "../Reducers/TutorialsReducer/index.d";
import tutorialsReducer from "../Reducers/TutorialsReducer";


export type StoreState = {

  editorFonts: EntityState<FontState>;
  tutorial: EntityState<TutorialsState>;
};
const configureKonvaEditorStore = (preloadedState?: StoreState) => {
  const store = configureStore({
    reducer: {

      editorFonts: editorFontsReducer,
      tutorial: tutorialsReducer,
    },
    // Remove later
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable the serializability check
      }),
  });
  return store;
};

export default configureKonvaEditorStore;
