import { useState } from "react";
import { CustomLabeledInput } from "../../components/v2/Input";
import { CustomParagraph } from "../../components/v2/Paragraph";
import { CustomFilledButton } from "../../components/v2/Button";

const MissingShopify = () => {
  const [shopName, setShopName] = useState("");

  const handleInputChange = (e: any) => {
    setShopName(e.target.value);
  };
  const handleTokenAdd = (e: any) => {
    e.preventDefault();
    handleNavigate();
  };
  const handleNavigate = () => {
    // Extract the store name from the shopName state
    let formattedShopName = shopName.trim();

    // Check if the input includes 'http://' or 'https://'
    if (formattedShopName.includes("http://")) {
      formattedShopName = formattedShopName.replace("http://", "");
    } else if (formattedShopName.includes("https://")) {
      formattedShopName = formattedShopName.replace("https://", "");
    }
    formattedShopName = formattedShopName.replace(/\/$/, "");
    // shopifyService
    //   .get_data(`/auth?shop=${}`)
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err))
    //   .finally(() => console.log);

    window.open(
      `${process.env.REACT_APP_ERP_BACKEND}/shopify/auth?shop=${formattedShopName}`,
      "_blank",
    );
  };
  return (
    <div className="flex h-screen w-full items-center justify-center bg-[#A793F6]  bg-gradient-to-l from-[#f7f4ff] to-[#c4aeff] ">
      <form
        onSubmit={handleTokenAdd}
        className="justify-top flex flex-col items-center gap-[35px] 
        rounded-[40px] bg-white px-[80px] py-[40px] lg:h-auto lg:w-[850px]"
      >
        <h1 className="my-2 text-2xl font-bold text-gray-800">
          Looks like the query parameters provided are no longer up to date.
        </h1>
        <CustomParagraph
          title="Enter your Shopify store URL below to link it with our platform for seamless product syncing and order management."
          className="text-left text-base text-[#79757f]"
        />
        <CustomLabeledInput
          title="Shopify Store URL"
          type="text"
          placeholder="domain.myshopify.com"
          required={true}
          value={shopName}
          onChange={handleInputChange}
        />
        <CustomFilledButton
          type="submit"
          className="max-w-max px-6 text-right"
          label={"Connect Store"}
        />
      </form>
    </div>
  );
};

export default MissingShopify;
