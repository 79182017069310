import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsRocketTakeoffFill, BsTagsFill } from "react-icons/bs";
import { FaImages, FaRegSadCry, FaToolbox } from "react-icons/fa";
import { HiBuildingStorefront } from "react-icons/hi2";
import { ImBooks } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";
import { MdSpaceDashboard } from "react-icons/md";
import { PiPlugsFill } from "react-icons/pi";
import { NavLink, useLocation } from "react-router-dom";
import "./index.css";
const menus = [
  { title: "Store Builder", href: "/", icon: FaToolbox },
  { title: "Dropshipping", href: "/dropshipping", icon: FaImages },
];
const extraMenus = [];

interface CustomSideElementInterface {
  title: string;
  href: string;
  isActive?: boolean;
  icon: any;
  isOpen: boolean;
  isActiveBgColor?: string;
  isActiveTextColor?: string;
}
const CustomSideElement = ({
  title,
  href,
  isActive = false,
  icon,
  isOpen,
  isActiveBgColor = "[#6839ee]/[0.08]",
  isActiveTextColor = "#6839ee",
}: CustomSideElementInterface) => {
  return (
    <NavLink
      className={`flex w-full flex-col justify-start gap-2.5 p-2
      text-[#79757f] 
      ${
        isActive
          ? `rounded-lg bg-${isActiveBgColor} text-${isActiveTextColor}`
          : `hover:text-${isActiveTextColor} `
      }
      ${isOpen ? "md:items-start" : " items-center"}

      `}
      style={{ color: isActive ? isActiveTextColor : "#79757f" }}
      to={href}
    >
      <div
        className={`inline-flex h-6 items-center justify-center  gap-4 md:justify-start ${
          isOpen ? "md:ml-2 " : ""
        }`}
      >
        <div className={`${isActive ? "" : " dark:text-main-pink-main"}`}>
          {React.createElement(icon, { size: 18 })}
        </div>
        <div className={`text-16 hidden  ${isOpen ? "md:block" : " "}`}>
          {title}
        </div>
      </div>
    </NavLink>
  );
};

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const currentPath = useLocation().pathname;
  return (
    <div
      className={`inline-flex h-full w-16 flex-col items-start justify-between gap-8   px-2
      pb-8 pt-5 text-black duration-500  ${isOpen ? "md:w-56 md:px-4" : ""} `}
    >
      <a id="logo" className=" flex w-full items-center justify-center gap-3">
        <img
          className="relative  rounded-full"
          src="/assets/logo/main.svg"
          alt="Launchify Logo"
        />
        {isOpen && (
          <img
            className="relative "
            src="/assets/logo/logo-text.svg"
            alt="Launchify Logo"
          />
        )}
      </a>
      <div
        id="top-section"
        style={{ maxHeight: "calc(100vh - 4rem)", overflowY: "auto" }}
        className="flex flex-col items-center justify-start gap-2  self-stretch overflow-x-hidden" // Add `justify-start` to place it at the start
      >
        {menus.map(({ title, href, icon }: any, idx: number) => {
          const isActive = currentPath === href;
          return (
            <CustomSideElement
              title={title}
              href={href}
              icon={icon}
              key={idx}
              isActive={isActive}
              isOpen={isOpen}
            />
          );
        })}
      </div>

      <div
        id="bottom-section"
        className="flex flex-col items-center justify-end gap-4 self-stretch"
      >
        {" "}
        <div className="relative hidden w-full md:block">
          <div
            id="openclose"
            className="absolute bottom-2 end-0 z-10 ml-auto mr-[-15px] cursor-pointer justify-end rounded-full
         bg-main-pink-main p-1 text-white dark:bg-main-pink-main dark:text-white"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen
              ? React.createElement(AiOutlineArrowLeft, { size: 30 })
              : React.createElement(AiOutlineArrowRight, { size: 30 })}
          </div>
        </div>
        {/* <div
          className="flex w-full flex-col items-start justify-start gap-2.5"
          id="helper-section"
        >
          {extraMenus.map(({ title, href, icon }: any, idx: number) => {
            const isActive = currentPath === href;
            return (
              <CustomSideElement
                title={title}
                href={href}
                icon={icon}
                key={idx}
                isActive={isActive}
                isOpen={isOpen}
                isActiveBgColor="[#b349ed]/[0.08]"
                isActiveTextColor="#9b17e4"
              />
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
