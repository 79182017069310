import React, { FC, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface CustomParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
  title?: string;
}

export const CustomParagraph: FC<CustomParagraphProps> = ({
  title,
  className,
  ...props
}) => {
  return (
    <h3
      className={twMerge(
        `flex-shrink-0 flex-grow-0 self-stretch text-left text-sm text-text-icons-grey-30`,
        className,
      )}
      {...props}
    >
      {title}
    </h3>
  );
};

export const Paragraph: FC<CustomParagraphProps> = ({
  title,
  className,
  ...props
}) => {
  return (
    <p
      className={twMerge(
        `text-left text-base  text-text-icons-dark-grey-10`,
        className,
      )}
      {...props}
    >
      {title}
    </p>
  );
};
