import "./App.css";

import { useRoutes } from "react-router-dom";

import queryString from "query-string"; // npm install query-string
import { ToastContainer } from "react-toastify";
import routes from "./router";

function App() {
  // Using the useRoutes hook to handle routing based on the defined routes
  const content = useRoutes(routes());
  // Extract the query parameters from the current URL
  const queryParams = queryString.parse(window.location.search);

  // The host parameter is required for the Shopify App Bridge and must be decoded
  const host: any = queryParams.host;
  const config = {
    apiKey: "eac94ee8e769014fb275c034d0ee50e5",
    host: host,
    forceRedirect: true,
  };

  return (
    // <Provider config={config}>
    <>
      <ToastContainer toastStyle={{ width: "fit-content" }} />
      <div className="App font-outfit">{content}</div>
    </>
    // </Provider>
  );
}

export default App;
